<template>
    <user-dashboard-layout>
        <v-form>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="token"
                            :loading="loading"
                            :disabled="loading"
                            label="Token"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-btn
                            color="success"
                            @click="generateToken"
                            :loading="loading"
                            v-text="'Generate'"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import Api from "../../../api/Api";

    export default {
        name: 'Index',
        components: {UserDashboardLayout},
        data() {
            return {
                loading: false,
                token: '',
            }
        },
        methods: {
            async generateToken() {
                this.loading = true;
                this.token = await Api.generateToken();
                this.loading = false;
            }
        }
    }
</script>

<style scoped>

</style>
